import React from "react"
import { useStaticQuery, graphql } from 'gatsby'

import '../components/bootstrap.min.css'
import '../components/all.css'
import '../components/theme.css'
import '../components/frontend.min.css'
import '../components/flaticon.css'
import '../components/designervily-base-icons.css'
import '../components/material-icons.css';

import logo from "../images/logo.png"

import "@fontsource/muli"
import "@fontsource/mulish"
import "@fontsource/nunito"

export default function Layout({ children }) {
    const data = useStaticQuery(graphql`
                    query {
                    site {
                        siteMetadata {
                        siteUrl
                        title
                        }
                    }
                    }
                `)

  return (
    <div>
        <title>{data.site.siteMetadata.title}</title>
        <header id="masthead" className="site-header dsvy-header-style-2">
            
            <div className="dsvy-header-height-wrapper">
                <div className="dsvy-header-inner dsvy-sticky-logo-no dsvy-responsive-logo-no dsvy-responsive-header-bgcolor-white dsvy-header-wrapper dsvy-bg-color-white dsvy-header-sticky-yes dsvy-sticky-type- dsvy-sticky-bg-color-white dsvy-sticky-applied dsvy-sticky-on">
                    <div className="d-flex justify-content-between dsvy-header-content">
                        <div className="site-branding dsvy-logo-area">
                            <div className="wrap">
                                
                                <h1 className="site-title">
                                    <a href="/" rel="home"><span className="site-title-text">{data.site.siteMetadata.title}</span>
                                        <img className="dsvy-main-logo" src={logo} alt={data.site.siteMetadata.title} title={data.site.siteMetadata.title} />
                                    </a>
                                </h1>
                            </div>
                        </div>
                        
                        <div className="dsvy-menu-inner d-flex justify-content-between align-items-center">
                            <button id="menu-toggle" className="nav-menu-toggle">
                                <div className="dsvy-header-button">
                                    <a href="/dojazd">
                                    <span className="dsvy-header-button-text-1">Dojazd</span>
                                    </a>
                                </div>
                            </button>            
                        </div>
                        <div className="dsvy-right-box">
                            <div className="dsvy-search-cart-box">
                                <div><a href="tel:618-610-123"><i className="dsvy-base-icon-call-1"></i>Rejestracja : 618-610-123</a></div>
                                <div>pon.-pt. 10.00-19.00</div>
                                <div>Poznań, ul. Hetmańska 15-19</div>
                            </div>
                            <div className="dsvy-header-button">
                                <a href="/dojazd">
                                    <span className="dsvy-header-button-text-1">Dojazd</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dsvy-header-height-wrapper">
                <div className="dsvy-address">
                        <div><a href="tel:618-610-123"><i className="dsvy-base-icon-call-1"></i>Rejestracja : 618-610-123</a></div>
                        <div>Poznań, ul. Hetmańska 15-19</div>
                        <div>pon.-pt. 10.00-19.00</div>
                    </div>
            </div>

        </header>

        {children}

        <footer id="colophon" className="dsvy-footer-section site-footer  dsvy-text-color-blackish dsvy-bg-color-light dsvy-footer-menu-yes dsvy-footer-widget-yes">
						
			<div className="dsvy-footer-section dsvy-footer-big-area-wrapper dsvy-bg-color-transparent">
				<div className="footer-wrap dsvy-footer-big-area">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-md-9">
                                <div className="d-md-flex align-items-center">
                                    <div className="d-md-flex"> 
										<div className="dsvy-footer-boxes dsvy-footer-boxes-1 col-md-6">
                                            <div className="dsvy-footer-contact-info">
                                                <div className="dsvy-footer-contact-info-inner d-flex align-items-center">
                                                    <a href="/dojazd"><i className="fas dsvy-base-icon-marker"></i> </a>
				                                    <div className="dsvy-footer-contact-info-wrap">
                                                    <a href="/dojazd"><span className="dsvy-label dsvy-label-1">Adres</span> Poznań, ul. Hetmańska 15-19</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dsvy-footer-boxes dsvy-footer-boxes-2 col-md-6">
                                            <div className="dsvy-footer-contact-info">
                                                <div className="dsvy-footer-contact-info-inner d-flex align-items-center">
                                                    <a href="tel:618-610-123"><i className="fas dsvy-base-icon-call-1"></i> </a>
				                                    <div className="dsvy-footer-contact-info-wrap">
                                                        <a href="tel:618-610-123">
                                                            <span className="dsvy-label dsvy-label-2">Rejestracja</span> 
                                                            Telefon : 618-610-123
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="dsvy-footer-boxes dsvy-footer-boxes-2 col-md-6">
                                            <div className="dsvy-footer-contact-info">
                                                <div className="dsvy-footer-contact-info-inner d-flex align-items-center">
                                    				<i className="fas dsvy-base-icon-clock"></i>
				                                    <div className="dsvy-footer-contact-info-wrap">
                                                        <span className="dsvy-label dsvy-label-2">Godziny otwarcia</span> 
                                                        pon.-pt. 10.00-19.00
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>	
                            </div>
                        </div>
					</div>
				</div>
			</div>
		</footer>
    </div>

  )
}